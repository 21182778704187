import styled from 'styled-components'
import brand from '@assets/brand/Shrey Realty Lux.png'
import UniqueId from './UniqueId'

const Container = styled.footer`
    background: var(--primary);
    color: white;
    box-sizing: border-box;
    padding: 2em;
    display: flex;
    gap: 1em;
    justify-content: space-between;
`

const FooterCopyright = styled.div`
    display: flex;
    flex-direction: column;
    img {
        height: 10em;
        filter: saturate(0%) brightness(150%);
    }
`

const FooterNavigation = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    // flex-grow: 1;

    a {
        color: white;
        text-decoration: none;
    }
`

const FooterCompanyDetails = styled.div`
    display: flex
    flex-direction: column;
    gap: 0.5em;
    // flex-grow: 1;
`

const InvestmentsId = `#${UniqueId.Investments}`
const AboutId = `#${UniqueId.Partners}`
const ContactId = `#${UniqueId.ContactUs}`
const BookCallId = `#${UniqueId.BookCall}`

export default function Footer() {
    return (
        <Container>
            <FooterCopyright>
                <img src={brand} />
                &copy; Shrey Realty, {new Date().getFullYear()}
            </FooterCopyright>
            <FooterNavigation>
                <strong>Sitemap</strong>
                <a href={InvestmentsId}>Investments</a>
                <a href={AboutId}>About Us</a>
                <a href={ContactId}>Contact Us</a>
                <a href={BookCallId}>Book a Call</a>
            </FooterNavigation>
            <FooterCompanyDetails>
                {/* <strong>Shrey Realty</strong>
                <br />
                <br />
                (609) 907 4049
                <br />
                <br />
                79 Franklin Drive
                <br />
                Plainsboro, NJ 08536 */}
            </FooterCompanyDetails>
        </Container>
    )
}
