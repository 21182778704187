import styled from 'styled-components'
import Headshot from '../Headshot'

const AboutPerson = styled.div`
    display: flex;
    flex-direction: column;
    background: var(--very-light);
    box-sizing: border-box;
    padding: 1em;
`

const AboutDetails = styled.div`
    flex-grow: 1;
`

const AboutPersonName = styled.h3`
    font-weight: 400;
    color: black;
    letter-spacing: 0.1em;
    font-size: 1.5em;
`

const AboutPersonDescription = styled.p`
    color: gray;
`

interface PartnerCardProps {
    name: string
    headshot: string
    bio: string
}

export default function PartnerCard({ name, headshot, bio }: PartnerCardProps) {
    return (
        <AboutPerson>
            <Headshot src={headshot} alt='Headshot' />
            <AboutDetails>
                <AboutPersonName>{name}</AboutPersonName>
                <AboutPersonDescription>{bio}</AboutPersonDescription>
            </AboutDetails>
        </AboutPerson>
    )
}
