import ThemeColor from '@/models/ThemeColor'
import { PropsWithChildren, useMemo } from 'react'
import styles from './Button.module.css'

interface ButtonProps extends PropsWithChildren {
    color?: ThemeColor
    style?: 'filled' | 'outline' | 'clear'
    onClick?: () => void
    className?: string
}

export default function Button({ children, onClick, color = ThemeColor.Blue, style = 'filled', className }: ButtonProps) {
    const fullClassName = useMemo(() => {
        const classes = [styles.Button]
        if (className) classes.push(className)
        if (color) classes.push(styles[color])
        if (style) classes.push(styles[style])
        return classes.join(' ')
    }, [className, color, style])

    return (
        <button onClick={onClick} className={fullClassName}>
            {children}
        </button>
    )
}
