import About from './components/About'
import Calendly from './components/Calendly'
import Contact from './components/Contact'
import Footer from './components/Footer'
import { Hero } from './components/Hero'
import InvestmentTypes from './components/InvestmentTypes/InvestmentTypes'
import NavigationBar from './components/Navigation/NavigationBar'
import { DeviceViewerContextProvider } from './hooks/device-viewer'

function App() {
    return (
        <DeviceViewerContextProvider>
            <Hero />
            <div>
                <NavigationBar />
                <InvestmentTypes />
                <About />
                <Contact />
                <Calendly />
                <Footer />
            </div>
        </DeviceViewerContextProvider>
    )
}

export default App
