import { MobileBreakpoint } from '@/models/DeviceSizes'
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react'

export interface IDeviceViewer {
    readonly width: number
    readonly height: number
    readonly isMobile: boolean
}

const DeviceViewerContext = createContext<IDeviceViewer>({
    width: 0,
    height: 0,
    isMobile: false
})

export function DeviceViewerContextProvider({ children }: PropsWithChildren) {
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)

    const viewer = useMemo<IDeviceViewer>(
        () => ({
            width,
            height,
            get isMobile() {
                return width <= MobileBreakpoint
            }
        }),
        [width, height]
    )

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
            setHeight(window.innerHeight)
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return <DeviceViewerContext.Provider value={viewer}>{children}</DeviceViewerContext.Provider>
}

export function useDeviceViewer(): IDeviceViewer {
    return useContext(DeviceViewerContext)
}
