import { PropsWithChildren, useMemo } from 'react'
import styles from './SectionBlock.module.css'

interface SectionBlockProps extends PropsWithChildren {
    id?: string
    minFullScreen?: boolean
    className?: string
    noBottomPadding?: boolean
    padEverything?: boolean
}

export default function SectionBlock({ children, id, minFullScreen, className, padEverything, noBottomPadding }: SectionBlockProps) {
    const fullClassName = useMemo(() => {
        const result = [styles.SectionBlock]
        if (minFullScreen) result.push(styles.MinFullScreen)
        if (noBottomPadding) result.push(styles.NoBottomPadding)
        if (padEverything) result.push(styles.PadEverything)
        if (className) result.push(className)
        return result.join(' ')
    }, [minFullScreen, noBottomPadding, padEverything, className])

    return (
        <div className={fullClassName}>
            <span id={id} className={styles.Spacer} />
            {children}
        </div>
    )
}
