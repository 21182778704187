import { IonIcon } from '@ionic/react'
import { useMemo } from 'react'
import styles from './Input.module.css'

interface InputProps {
    type?: string
    placeholder?: string
    icon?: string
    size?: string
    className?: string
}

export default function Input({ icon, type, placeholder, size = '1em', className }: InputProps) {
    const fullClassName = useMemo(() => {
        const result = [styles.InputContainer]
        if (className) result.push(className)
        return result.join(' ')
    }, [className])

    return (
        <div className={fullClassName}>
            {icon && <IonIcon icon={icon} />}
            <input type={type} placeholder={placeholder} />
        </div>
    )
}
