import { ChangeEventHandler, useCallback, useMemo } from 'react'
import styles from './TextBox.module.css'

export interface TextBoxChangeEventDetail {
    label?: string
    value: string
}

interface TextBoxProps {
    value: string
    className?: string

    onChange: (detail: TextBoxChangeEventDetail) => void
}

export default function TextBox({ value, className, onChange }: TextBoxProps) {
    const fullClassName = useMemo(() => {
        const result = [styles.TextBox]
        if (className) result.push(className)
        return result.join(' ')
    }, [className])

    const handleChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
        event => {
            onChange({ value: event.currentTarget.value })
        },
        [onChange]
    )

    return <textarea className={fullClassName} onChange={handleChange} value={value} />
}
