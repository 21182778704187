import { useDeviceViewer } from '@/hooks/device-viewer'
import brandImage from '@assets/brand/Shrey Realty Lux.png'
import UniqueId from '@components/UniqueId'
import styles from './NavigationHalo.module.css'

const InvestmentsId = `#${UniqueId.Investments}`
const PartnersId = `#${UniqueId.Partners}`
const ContactUsId = `#${UniqueId.ContactUs}`

export default function NavigationHalo() {
    const deviceViewer = useDeviceViewer()

    return (
        <div className={styles.Halo}>
            <img src={brandImage} alt='Brand' className={styles.BrandImage} />

            {!deviceViewer.isMobile && (
                <div className={styles.Menu}>
                    <a href={InvestmentsId}>Investments</a>
                    <a href={PartnersId}>Partners</a>
                    <a href={ContactUsId}>Contact Us</a>
                </div>
            )}
        </div>
    )
}
