import UniqueId from '@components/UniqueId'
import InvestmentType from '@models/InvestmentType'
import { useCallback, useState } from 'react'
import SectionBlock from '../Section/SectionBlock'
import SectionHeading from '../Section/SectionHeading'
import InvestmentTypeCard from './InvestmentTypeCard'
import styles from './InvestmentTypes.module.css'
import fixAndFlipBackground from './house-fixing.png'
import residentialBackground from './residential.png'
import storageFacilityBackground from './storage-facility.png'
import townhomesBackground from './townhomes.png'
import { useDeviceViewer } from '@/hooks/device-viewer'

const investments: InvestmentType[] = [
    {
        name: 'Multi-Family Homes',
        image: townhomesBackground,
        description: (
            <>
                This investment focuses on properties such as apartment buildings, duplexes, and townhomes. The key advantage is the ability to generate consistent rental income
                from multiple tenants, which can provide more financial stability than single-tenant properties. Additionally, economies of scale in maintenance and management can
                make multi-family properties more cost-effective. This type of investment is ideal for investors looking for long-term income generation and capital appreciation.
            </>
        )
    },
    {
        name: 'Self-Storage',
        image: storageFacilityBackground,
        description: (
            <>
                These involve investing in facilities offering storage spaces for personal and commercial use. The self-storage market often remains robust due to constant demand
                from individuals needing extra space and businesses requiring storage for inventory or equipment. These investments typically involve lower tenant turnover and
                operational costs compared to traditional residential or commercial real estate, making them an attractive option for steady cash flow with relatively low
                management intensity.
            </>
        )
    },
    {
        name: 'Residential',
        image: residentialBackground,
        description: (
            <>
                This encompasses the purchase and management of properties such as single-family homes, condominiums, and other residential units. Investors can benefit from
                long-term capital growth, potential tax advantages, and the flexibility to adapt strategies—like renting for long-term income or short-term vacation rentals.
                Residential real estate is also impacted by a broader range of market dynamics, including interest rates, housing demand, and regional economic health, offering
                diverse investment opportunities.
            </>
        )
    },
    {
        name: 'Fix & Flip',
        image: fixAndFlipBackground,
        description: (
            <>
                This strategy involves purchasing undervalued properties, renovating them to add value, and then selling them at a higher price. It's well-suited for investors who
                have a good understanding of real estate markets and renovation costs, and who can efficiently manage the renovation process. While fix & flip offers the potential
                for significant short-term profits, it also requires a higher tolerance for risk and an ability to swiftly navigate market fluctuations and property valuation
                changes.
            </>
        )
    }
]

export default function InvestmentTypes() {
    const [selectedInvestmentType, setSelectedInvestmentType] = useState<InvestmentType>(investments[0])
    const deviceViewer = useDeviceViewer()

    const toggleInvestmentType = useCallback((type: InvestmentType) => {
        setSelectedInvestmentType(type)
    }, [])

    return (
        <SectionBlock id={UniqueId.Investments} minFullScreen noBottomPadding>
            <div className={styles.ExpandedCard}>
                <SectionHeading>
                    I Invest in{deviceViewer.isMobile ? <br /> : ' '}
                    <span className={styles.HeadingInvestmentName}>
                        {selectedInvestmentType.name}
                        <strong>.</strong>
                    </span>
                </SectionHeading>

                <p>{selectedInvestmentType.description}</p>

                <div className={styles.Message}>Click a card below to learn more</div>
            </div>

            <div className={`${styles.CardsContainer} ${deviceViewer.isMobile ? styles.Mobile : ''}`.trim()}>
                {investments.map(investment => (
                    <InvestmentTypeCard investmentType={investment} onHover={toggleInvestmentType} />
                ))}
            </div>
        </SectionBlock>
    )
}
