import styled from 'styled-components'

interface HeadshotProps {
    src: string
    alt: string
}

const HeadshotContainer = styled.div`
    --border-size: 2em;
    --border-offset: -1em;

    position: relative;

    img {
        width: 100%;
        height: 100%;
        aspect-ratio: 1/1;
        transition: 250ms all;
    }

    &:hover img {
        scale: 1.1;
        filter: brightness(1.05);
    }
`

const Box1 = styled.div`
    width: var(--border-size);
    height: var(--border-size);
    position: absolute;
    top: var(--border-offset);
    left: var(--border-offset);
    border-top: 3px solid #0870d1;
    border-left: 3px solid #0870d1;
`

const Box2 = styled.div`
    width: var(--border-size);
    height: var(--border-size);
    position: absolute;
    bottom: var(--border-offset);
    right: var(--border-offset);
    border-bottom: 3px solid #0870d1;
    border-right: 3px solid #0870d1;
`

export default function Headshot({ src, alt }: HeadshotProps) {
    return (
        <HeadshotContainer>
            <img src={src} alt={alt} />
            <Box1 />
            <Box2 />
        </HeadshotContainer>
    )
}
