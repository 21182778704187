import styled from 'styled-components'
import SectionHeading from './Section/SectionHeading'
import SectionBlock from './Section/SectionBlock'
import UniqueId from './UniqueId'
import { useDeviceViewer } from '@/hooks/device-viewer'

const CalendlyFrame = styled.iframe`
    border: none;
    width: 100%;
    min-height: 550px;
    border-radius: 1em;

    &[data-mobile='true'] {
        width: 90%;
    }
`

export default function Calendly() {
    const deviceViewer = useDeviceViewer()

    return (
        <SectionBlock id={UniqueId.BookCall} padEverything={!deviceViewer.isMobile}>
            <SectionHeading>Book a Call</SectionHeading>

            <CalendlyFrame src='https://calendly.com/write2rocky68' data-mobile={deviceViewer.isMobile}></CalendlyFrame>
        </SectionBlock>
    )
}
