import { IonIcon } from '@ionic/react'
import { checkmarkCircle, ellipseOutline } from 'ionicons/icons'
import { useCallback, useMemo } from 'react'
import styles from './CheckBox.module.css'

export interface CheckBoxChangeEventDetail {
    label: string
    checked: boolean
}

interface CheckBoxProps {
    checked: boolean
    label: string
    className?: string
    onChange: (event: CheckBoxChangeEventDetail) => void
}

export default function CheckBox({ checked, onChange, className, label }: CheckBoxProps) {
    const fullClassName = useMemo(() => {
        const result = [styles.CheckBox]
        if (className) result.push(className)
        if (checked) result.push(styles.Checked)
        return result.join(' ')
    }, [checked, className])

    const handleChange = useCallback(() => {
        onChange({ checked: !checked, label })
    }, [checked, label, onChange])

    return (
        <button className={fullClassName} onClick={handleChange}>
            <IonIcon icon={checked ? checkmarkCircle : ellipseOutline} />
            {label}
        </button>
    )
}
