import styled from 'styled-components'
import Button from './Button'
import NavigationHalo from './Navigation/NavigationHalo'
import UniqueId from './UniqueId'

const FullScreenHero = styled.div`
    width: 100vw;
    height: 100vh;
    background: url('https://images.unsplash.com/photo-1583608205776-bfd35f0d9f83?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-size: cover;
    background-attachment: fixed;
`

const FullScreenHeroOverlay = styled.div`
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`

const HeroContent = styled.div`
    padding: 0 50px;
`

const HeroText = styled.h1`
    font-weight: bold;
    color: white;
    text-align: center;

    span {
        color: gold;
    }
`

const BookCallId = `#${UniqueId.BookCall}`

export function Hero() {
    return (
        <FullScreenHero>
            <FullScreenHeroOverlay>
                <NavigationHalo />
                <HeroContent>
                    <HeroText>
                        Find your dream home
                        <br />
                        with <span>Shrey Realty</span>
                    </HeroText>
                </HeroContent>

                <a href={BookCallId}>
                    <Button>
                        <strong>Book a Call</strong>
                    </Button>
                </a>
            </FullScreenHeroOverlay>
        </FullScreenHero>
    )
}
