import { MobileBreakpoint } from '@/models/DeviceSizes'
import ThemeColor from '@/models/ThemeColor'
import logoSquare from '@assets/brand/Shrey Realty Lux.png'
import UniqueId from '@components/UniqueId'
import { IonIcon } from '@ionic/react'
import { closeOutline, menuOutline } from 'ionicons/icons'
import { useCallback, useEffect, useState } from 'react'
import Button from '../Button'
import styles from './NavigationBar.module.css'

const InvestmentsId = `#${UniqueId.Investments}`
const PartnersId = `#${UniqueId.Partners}`
const ContactUsId = `#${UniqueId.ContactUs}`
const BookCallId = `#${UniqueId.BookCall}`

export default function NavigationBar() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isClosingMenu, setIsClosingMenu] = useState(false)

    useEffect(() => {
        const resizeHandler = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', resizeHandler)
        return () => window.removeEventListener('resize', resizeHandler)
    })

    const openMenu = useCallback(() => setIsMenuOpen(true), [])

    const closeMenu = useCallback(() => {
        setIsClosingMenu(true)
        setTimeout(() => {
            setIsMenuOpen(false)
            setIsClosingMenu(false)
        }, 1000)
    }, [])

    if (windowWidth >= MobileBreakpoint) {
        return (
            <div className={styles.NavBar}>
                <img src={logoSquare} alt='Brand (Thick)' className={styles.BrandImage} />

                <div className={styles.NavigationMenu}>
                    <NavigationMenuLinks closeMenu={closeMenu} />
                </div>
            </div>
        )
    } else {
        return (
            <>
                <div className={`${styles.NavBar} ${styles.Mobile}`}>
                    <img src={logoSquare} alt='Brand (Thick)' className={styles.BrandImage} />
                    <div className={styles.NavigationMenu}>
                        <Button style='clear' color={ThemeColor.White} className={styles.MobileMenuButton} onClick={openMenu}>
                            Menu
                            <IonIcon icon={menuOutline} size='large' />
                        </Button>
                    </div>
                </div>

                {isMenuOpen && (
                    <div className={`${styles.MobileMenu} ${isClosingMenu ? styles.Closing : ''}`.trim()}>
                        <img src={logoSquare} alt='Brand (Thick)' className={styles.BrandImage} />

                        <div className={styles.MobileNavigationMenu}>
                            <NavigationMenuLinks closeMenu={closeMenu} />
                        </div>

                        <Button className={styles.CloseButton} onClick={closeMenu}>
                            <IonIcon icon={closeOutline} />
                        </Button>
                    </div>
                )}
            </>
        )
    }
}

function NavigationMenuLinks(props: { closeMenu: () => void }) {
    return (
        <>
            <a href={InvestmentsId} onClick={props.closeMenu}>
                Investments
            </a>
            <a href={PartnersId} onClick={props.closeMenu}>
                Partners
            </a>
            <a href={ContactUsId} onClick={props.closeMenu}>
                Contact Us
            </a>
            <a href={BookCallId} onClick={props.closeMenu}>
                Book a Call
            </a>
        </>
    )
}
