import InvestmentType from '@models/InvestmentType'
import { useCallback } from 'react'
import styles from './InvestmentTypeCard.module.css'
import { useDeviceViewer } from '@/hooks/device-viewer'

interface InvestmentTypeCardProps {
    investmentType: InvestmentType
    onHover: (type: InvestmentType) => void
}

export default function InvestmentTypeCard({ investmentType, onHover }: InvestmentTypeCardProps) {
    const handleHover = useCallback(() => onHover(investmentType), [investmentType, onHover])
    const deviceViewer = useDeviceViewer()

    if (deviceViewer.isMobile) {
        return (
            <div className={`${styles.Card} ${styles.Mobile}`} onMouseOver={handleHover}>
                <div className={styles.ContainsWithin}>
                    <img className={styles.Image} src={investmentType.image} alt={investmentType.name} />
                </div>

                <div className={styles.CardLabel}>{investmentType.name}</div>
            </div>
        )
    }

    return (
        <div className={styles.Card} onMouseOver={handleHover}>
            <div className={styles.ContainsWithin}>
                <img className={styles.Image} src={investmentType.image} alt={investmentType.name} />
            </div>

            <div className={styles.CardLabel}>{investmentType.name}</div>
        </div>
    )
}
