import { atOutline, callOutline, personOutline } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import Button from './Button'
import CheckBox, { CheckBoxChangeEventDetail } from './CheckBox/CheckBox'
import styles from './Contact.module.css'
import Input from './Input/Input'
import TextBox, { TextBoxChangeEventDetail } from './TextBox/TextBox'
import UniqueId from './UniqueId'
import SectionHeading from './Section/SectionHeading'
import SectionBlock from './Section/SectionBlock'

const FormRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    box-sizing: border-box;
    width: 100%;
`

const FormMessage = styled.div`
    box-sizing: border-box;
    margin-top: 0.5em;
    width: 100%;
`

const Spacer = styled.div`
    flex-grow: 1;
`

const opportunities = ['Multi-Family', 'Self-Storage', 'Residential', 'Fix & Flip']

export default function Contact() {
    const [checkedOpportunities, setCheckedOpportunities] = useState<string[]>([])
    const [message, setMessage] = useState('')

    const handleChange = useCallback(
        (detail: CheckBoxChangeEventDetail) => {
            if (detail.checked) setCheckedOpportunities([...checkedOpportunities, detail.label])
            else setCheckedOpportunities(checkedOpportunities.filter(opportunity => opportunity !== detail.label))
        },
        [checkedOpportunities]
    )

    const handleMessageChange = useCallback((event: TextBoxChangeEventDetail) => {
        setMessage(event.value)
    }, [])

    return (
        <SectionBlock id={UniqueId.ContactUs} className={styles.SectionBlock} padEverything>
            <SectionHeading>Contact Us</SectionHeading>

            <FormRow>
                <Input icon={personOutline} placeholder='Name' size='1.5em' className={styles.GrowInput} />
                <Input icon={atOutline} placeholder='Email' size='1.5em' className={styles.GrowInput} />
                <Input icon={callOutline} placeholder='Phone' size='1.5em' className={styles.GrowInput} />
            </FormRow>

            <FormMessage>Select the type of your opportunity:</FormMessage>

            <FormRow>
                {opportunities.map(opportunity => (
                    <CheckBox key={opportunity} label={opportunity} onChange={handleChange} checked={checkedOpportunities.includes(opportunity)} />
                ))}
            </FormRow>

            <FormMessage>Message:</FormMessage>

            <FormRow>
                <TextBox value={message} onChange={handleMessageChange} className={styles.MessageBox} />
            </FormRow>

            <FormRow>
                <Spacer />
                <Button>Submit</Button>
            </FormRow>
        </SectionBlock>
    )
}
