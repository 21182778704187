import styled from 'styled-components'
import PartnerCard from './PartnersSection/PartnerCard'
import SectionBlock from './Section/SectionBlock'
import SectionHeading from './Section/SectionHeading'
import UniqueId from './UniqueId'
import { useDeviceViewer } from '@/hooks/device-viewer'

const AboutPersonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    gap: 2em;

    &[data-mobile='true'] {
        flex-wrap: wrap;
    }

    div {
        flex-grow: 1;
    }
`

export default function About() {
    const deviceViewer = useDeviceViewer()

    return (
        <SectionBlock id={UniqueId.Partners} padEverything>
            <SectionHeading>Partners</SectionHeading>

            <AboutPersonContainer data-mobile={deviceViewer.isMobile}>
                <PartnerCard headshot='https://imgv3.fotor.com/images/gallery/Realistic-Male-Profile-Picture.jpg' name='Shreyash Singh' bio='Lorem Ipsum Dolor Sit Amet.' />
                <PartnerCard headshot='https://imgv3.fotor.com/images/gallery/Realistic-Male-Profile-Picture.jpg' name='Rakesh Singh' bio='Lorem Ipsum Dolor Sit Amet.' />
            </AboutPersonContainer>
        </SectionBlock>
    )
}
